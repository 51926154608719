	/* change theme starts */
	idelement = document.getElementById("csstheme_sp"); /* id for class change */
	
	/* teal button click */
	function tealthemefun() {	
		
		idelement.classList.add("teal_theme");
		idelement.classList.remove("blue_theme", "grey_theme", "red_theme");
		addPendo();
	}


	/* blue button click */
	function bluethemefun() {
		idelement.classList.add("blue_theme");	
		idelement.classList.remove("grey_theme", "red_theme","teal_theme");
		addPendo();
	}

	/* grey button click */
	function greythemefun() {
		idelement.classList.add("grey_theme");
		idelement.classList.remove("blue_theme", "red_theme","teal_theme");
		addPendo();
	}

	/* red button click */
	function redthemefun() {	
		idelement.classList.add("red_theme");
		idelement.classList.remove("blue_theme" , "grey_theme","teal_theme");
		addPendo();
	}
	function setTheme(){
		idelement.classList.remove("blue_theme", "grey_theme", "red_theme");
		idelement.classList.add(sessionStorage.getItem("name"));
		
	}
	function buttonthemefun() {
		var fieldvalue = document.getElementById("csstheme_sp").getAttribute("class");
        sessionStorage.setItem("name",fieldvalue);
	}
        
    function loadcss() {
        var namevalue = sessionStorage.getItem("name");
        if(namevalue){
			//$("[id=csstheme_sp]").attr({class : namevalue});
			document.getElementById("csstheme_sp").setAttribute("class" , namevalue)
        }        
	}

	setTimeout(loadcss,2);
	/* change theme end */	

	if(JSON.parse(sessionStorage.getItem("loggedUserData")))
		addPendo();
$(document).ready(function (e) {

	/* adjust tree model position after adding dimention */
	$(".add_dim_btn, .cnvasSection .modal").on("click", function(){
		$(".sourcebox .ng-draggable").css("transform","translate(0px, 0px)" )
	})

	$(document).on("mouseover", ".graphbox", function(){
		$(this).addClass("shownextpre")
	});

	$(document).on("mouseout", ".graphbox", function(){
		$(this).removeClass("shownextpre")
	})

	$(document).on("click", ".profileclick", function(){
		$(".profilec").addClass("profileshow");
		$(".notificationec").removeClass("notificationshow");
	})

	$(document).on("click", ".notificationclick", function(){
		$(".notificationec").toggleClass("notificationshow");
		$(".profilec").removeClass("profileshow");
	});

	$(document).on("click", ".closenotification, .notificationbg", function(){
		$(".notificationec").removeClass("notificationshow");
	})

	$("body").on("click", ".nocb .multiselect-item-checkbox", function(){
		$(this).parents(".nocb").find(".dropdown-list").hide();
	});

	$("body").on("click", ".nocb .dropdown-btn", function(){
		$(this).parents(".nocb").find(".dropdown-list").show();
	}) 

	$("body").on("click", ".showrb .jobstable .mr-table-body-row", function(){
		$(".mr-table-body-row").removeClass("activetr")
		$(this).addClass("activetr")
	});	

	$("body").on("click", ".toggleAddBtn", function(){
		$(this).parent(".togglec").addClass("showtoggle");
	})

	$("body").on("click", ".toggleRemBtn", function(){
		$(this).parent(".togglec").removeClass("showtoggle");
	})

	$("body").on("click", "#modeltreeclose", function(){
		$(".vcube").addClass("hidetree");
		$(".vmfullc").removeClass("threeboxes");
	})

    $(".drop-dwn-arw").on('click', function () {
        var $rowSelector = $(this).closest('.mr-tbl-first-row');
        
        if ($rowSelector.hasClass('mr-open')) {
            $rowSelector.removeClass('mr-open');
            $rowSelector.siblings().addClass('mr-rw-toggle');
        }
        else {          
            $('.mr-tbl-first-row').removeClass('mr-open');
            $('.mr-tbl-first-row').siblings().addClass('mr-rw-toggle'); 
            $rowSelector.addClass('mr-open');
            $rowSelector.siblings().removeClass('mr-rw-toggle');
        }    
        return false;
    });
    return false;

    // for dropdown
    $('.select-box .dropdown-menu li').click(function () {
        $(this).parent().siblings('.dropdown-toggle').text($(this).text());
    });

    // for card dropdown
    $('.card-comp-wrap .dropdwn-toggler').click(function () {
        $(this).next().toggleClass('mr-toggle');
        rotateArrow($(this));
        $('.done-btn').click(function () {
            let $eleSelector = $(this).parent()
            $eleSelector.addClass('mr-toggle');
            $eleSelector.siblings('.select-li-txt').find('img').removeClass('rotate-arrow');
        });
    });

    // query table active tab change
    $('.query-table-head li').click(function () {
        $(this).addClass('active-tab').siblings().removeClass('active-tab');
    });

    $('.table-wth-subfield-wrp .mr-flex-wrp').click(function () {
        $(this).siblings().toggleClass('mr-toggle');
        rotateArrow($(this));
    });

    // empty field check 
    $('input.mandetory-field').on("keyup focusout", function () {
        emptyFieldCk($(this));
    });

	
	
});

$(document).ready(function(){
		
	/* input label active starts here
	/* commented the function to show label if field has titile  */
	/* $(document).on("focus", ".input-field" , function(){
		$(this).parents(".fieldc").find(".inputlabel").css("display", "none");
	}) 
	
	$(document).on("focusout", ".input-field" , function(){
		if($(this).val().length >= 1 )
			{	
			$(this).parents(".fieldc").find(".inputlabel").css("display", "block")
			}
	}); */
	/* input label active end here */
	
	/* progress bar tab ** add class completetab to previous li */
	$(document).on("click", ".progressbtnc li" , function(){		
		$(".progressbtnc li").removeClass("completetab");
		$(this).addClass( "completetab" );
		$(this).prevAll("li").addClass( "completetab" );
		$(".tab-pane").removeClass("show")
		$(this).parents(".tab-pane").next(".tab-pane").addClass("show");
	});

	$(document).on("click", ".progressbtnc li.nohover" , function(){		
		$(".progressbtnc li").removeClass("completetab");
		$(this).removeClass( "completetab" );
		$(this).prevAll("li").removeClass( "completetab" );
		$(".tab-pane").removeClass("show")
		$(this).parents(".tab-pane").next(".tab-pane").removeClass("show");
	});
	
	/* progress step next button */
	$(document).on("click", ".btnnext" , function(){
		var idval = $(this).parents(".tab-pane").attr("id");
		$(".tab-pane").removeClass("show")
		$(this).parents(".tab-pane").next(".tab-pane").addClass("show");
		
		
		$(".tabbtn").each(function(){			
			if($(this).attr("href") == "#" +idval) {
				$(this).parent(".tabli").next().addClass("completetab")
			} 
		})		
	}); 
	
	/* progress step previous button */
	$(document).on("click", ".btnpre" , function(){
		var idval = $(this).parents(".tab-pane").attr("id");
		$(".tab-pane").removeClass("show")
		$(this).parents(".tab-pane").prev(".tab-pane").addClass("show");
		
		
		$(".tabbtn").each(function(){			
			if($(this).attr("href") == "#" +idval) {
				$(this).parent(".tabli").removeClass("completetab")
			} 
		})		
	}); 
	
	/* tree item */
	$(document).on("click", ".selectli", function(){
		$(".selectli").removeClass("active");
		$(this).addClass("active")
	})
	
	/* transfer table row */
	
	
	$(document).on("click", ".toright", function(){
		var selectedtr = $('input:checked').parents(".inrtr");
		$(this).parents(".transfer-ec").find(".transfer-r tbody").append(selectedtr)
	});
	
	$(document).on("click", ".toleft", function(){
		var selectedtr = $('input:checked').parents(".inrtr");
		$(this).parents(".transfer-ec").find(".transfer-l tbody").append(selectedtr)
	})
	
	/* select all */
	$(document).on("click", ".selectall", function(){
		$(this).parents("table").find(".inrtr input").prop('checked', true);		
	})
	
	/* append */
	$(document).on("click", ".addappend" , function(){
		var appendhere = $(this).parents(".appendec").find(".appendfile .appendc");
		$(appendhere).clone().first().appendTo(".appendhere");
	})
	
	$(document).on("click", ".deleteicon", function(){
		 $(this).parents(".appendc").remove()
	})

	/* append one level*/
	$(document).on("click", ".addappend-p" , function(){
		var appendhere = $(this).parent(".appendec").find(".appendfile .appendc");
		$(appendhere).clone().first().appendTo(".appendhere");
	})
	
	$(document).on("click", ".deleteicon-p", function(){
		 $(this).parent(".appendc").remove()
	})
	
	$(document).on("click", ".addappend-sd" , function(){
		var appendhere = $(this).parents(".appendecsd").find(".appendfilesd .appendcsd");
		$(appendhere).clone().first().appendTo(".appendhere");
	})

	$(document).on("click", ".deleteicon-p", function(){
		$(this).parent(".appendcsd").remove()
   })

   /* not add more than 5 files 
  	 $(document).on("click", ".addappendLimit", function() {
		if (($(this).parent(".appendec").find(".appendhere .appendc")[0]).length() > 2) {
			alert("You can select only 2 images");
		} else {
			alert("testing")
			var appendhere = $(this).parent(".appendec").find(".appendfile .appendc");
			$(appendhere).clone().first().appendTo(".appendhere");
		}
	}); */
	
	/* file upload */
	$(".custom-file-input").on("change", function() {
	  var fileName = $(this).val().split("\\").pop();
	  $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
	});
	
	$(".uploadfile-input").on("change", function() {
	  var fileNames = $(this).val().split("\\").pop();
	  $(this).parents(".uploadfile-ec").find(".uploadedfile-txt").addClass("selected").html(fileNames);
	});
	
})

function rotateArrow(_this) {
    _this.find('img').toggleClass('rotate-arrow');
}
function addPendo(){
	theme=document.getElementById("csstheme_sp").getAttribute("class");
	
	var user=JSON.parse(sessionStorage.getItem("loggedUserData"));
	if(!theme)
	theme=sessionStorage.getItem("name");
	if(!theme)
  theme="teal_theme";
	(function(apiKey){
		(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
		v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
			o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
			y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
			z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })('d772026e-4e0d-4c18-7494-3d99538240ae');
  pendo.initialize({
	visitor: {
		id:              user.username,   // Required if user is logged in
		email:        user.emailId,// Recommended if using Pendo Feedback, or NPS Email
		full_name:    user.firstName+' '+user.lastName,// Recommended if using Pendo Feedback
		role:         user.authorities,// Optional
		module:		  'USTIQ_Explorer',
		theme: theme
		// You can add any additional visitor level key-values here,
		// as long as it's not one of the above reserved names.
	},

	account: {
		id:           user.username // Required if using Pendo Feedback
		// name:         // Optional
		// is_paying:    // Recommended if using Pendo Feedback
		// monthly_value:// Recommended if using Pendo Feedback
		// planLevel:    // Optional
		// planPrice:    // Optional
		// creationDate: // Optional

		// You can add any additional account level key-values here,
		// as long as it's not one of the above reserved names.
	}
});
pendo.identify({
	visitor: {
		id:           user.username,   // Required if user is logged in
		email:        user.emailId,// Recommended if using Pendo Feedback, or NPS Email
		full_name:    user.firstName+' '+user.lastName,// Recommended if using Pendo Feedback
		role:         user.authorities,// Optional
		module:		  'USTIQ_Explorer',
		theme: theme
	},
	account: {
		id:    user.username,
	}
});
}
/* tree item  */
var toggler = document.getElementsByClassName("liclick");
var i;

for (i = 0; i < toggler.length; i++) {
  toggler[i].addEventListener("click", function() {
    this.parentElement.querySelector(".nested").classList.toggle("active");
    this.classList.toggle("caret-down");
  });
}


/* drag and drop */

function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}

setInterval(function() {
  $(".leftdropc").append($(".leftdropdiv").find(".flex-container"));
  $(".rightdropc").append($(".rightdropdiv").find(".flex-container"))
}, 10);

$( document ).ready(function() {
	var contenth = $( window ).height() - 110 ;
	var sidebarh = $( window ).height() - 111 ;
	$(".pagec").css("height", contenth );
	$(".sidebar-wrapper").css("height", sidebarh );
});

$( window ).resize(function() {
	var contenth = $( window ).height() - 110 ;
	var sidebarh = $( window ).height() - 111 ;
	$(".pagec").css("height", contenth );
	$(".sidebar-wrapper").css("height", sidebarh );
});